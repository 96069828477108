exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogg-tsx": () => import("./../../../src/pages/blogg.tsx" /* webpackChunkName: "component---src-pages-blogg-tsx" */),
  "component---src-pages-erbjudanden-tsx": () => import("./../../../src/pages/erbjudanden.tsx" /* webpackChunkName: "component---src-pages-erbjudanden-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobb-tsx": () => import("./../../../src/pages/jobb.tsx" /* webpackChunkName: "component---src-pages-jobb-tsx" */),
  "component---src-pages-keepitsecure-tsx": () => import("./../../../src/pages/keepitsecure.tsx" /* webpackChunkName: "component---src-pages-keepitsecure-tsx" */),
  "component---src-pages-kitscon-tsx": () => import("./../../../src/pages/kitscon.tsx" /* webpackChunkName: "component---src-pages-kitscon-tsx" */),
  "component---src-pages-om-tsx": () => import("./../../../src/pages/om.tsx" /* webpackChunkName: "component---src-pages-om-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

